.DataView {
  display: flex;
  padding: 1em;
  height: 32vh;
}

.Sources {
  background-color: #0e1219;
  width: 200px;
}

.SourceItem {
  background-color: #202731;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  height: 30px;
  margin: 1em;
  cursor: pointer;
}
.SourceItem:hover {
  background-color: #db0a41;
}
.Node {
  padding-left: 1em;
  display: flex;
  flex-direction: column;
}

.NodeContent {
  display: flex;
  align-items: center;
}

.DataContent {
  padding: 1em;
  margin-left: 1em;
  background-color: #0e1219;
  width: 100%;
  overflow-y: scroll;
  max-height: 100%;
}

.Expand {
  cursor: pointer;
}

.Name {
  background-color: #202731;
  border-radius: 4px;
  padding: 0.2em;
  margin: 0.2em;
  cursor: pointer;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.Value {
  color: #8a8a8a;
  margin-left: 1em;
}

.Code {
  width: 100%;
}

.Item {
  background-color: #202731;
  padding: 0.5em;
}

.Active {
  background-color: #db0a41;
}

.DataSource {
  width: 100%;
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.DataSourceHeader {
  display: flex;
}

.DataSource input {
  background-color: #303341;
  outline: none;
  border: none;
  padding: 0.5em;
  color: #db0a41;
  border-radius: 4px;
  width: 80%;
  font-size: 1em;
  height: 15px;
  margin: 0.5em;
  /* font-weight: 100; */
}
.DataSource input::selection {
  background-color: #4b5063;
}

.Main {
  display: flex;
  height: 100%;
  width: 100%;
}
