textarea {
  background-color: #16181f;
  border: none;
  height: 60px;
  color: #8a8c8e;
  outline: none;
  font-size: 1em;
  width: 100%;
  border-radius: 4px;
  padding: 0.4em;
  box-sizing: border-box;
}
textarea:focus {
  border-bottom: 1px solid #db0a41;
}

.Color {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.lists {
  display: flex;
  flex-direction: column;
}
.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
}
.Image {
  background-color: #252734;
  width: 100%;
  height: 140px;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.Title {
  width: 30%;
}

.Group {
  padding: 0.5em;
}
.Context {
  width: 150px;
  background-color: rgb(32, 39, 49);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 4px 0px 1px, rgba(0, 0, 0, 0.15) 2px 4px;
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.ContextItem {
  cursor: pointer;
  border-bottom: 1px solid #303341;
  padding: 0.4em;
}
.ContextItem:hover {
  background-color: #db0a41;
}

.UploadButton {
  width: 40%;
  height: 20px;
  padding: 0.2em;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  background-color: #303341;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Property input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  height: 20px;
  color: #8a8c8e;
  outline: none;
  font-size: 15px;
  cursor: ew-resize;
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
}

.CheckboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.CheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.Checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #12161d;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.CheckboxContainer:hover input ~ .Checkmark {
  border: 1px solid white;
}

/* When the checkbox is checked, add a blue background */
.CheckboxContainer input:checked ~ .Checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.CheckboxContainer input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.CheckboxContainer .Checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
