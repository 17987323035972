.Header {
  padding: 0.5em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #343848;
  font-weight: 100;
}
.Header svg {
  height: 1em;
  margin-left: auto;
}
.Header .Item {
  width: 20%;
  cursor: pointer;
}
.Header .Item:hover {
  color: #db0a41;
}
.ProjectList {
  padding: 0.5em;
}
.Project {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
}
.Scene {
  padding-left: 5px;
  cursor: pointer;
  display: flex;
  min-width: 200px;
  flex-direction: column;
}
.Scene.Active .SceneName {
  background-color: #db0a41;
}
.NewScene {
  margin-left: auto;
  cursor: pointer;
}
.SceneName {
  display: flex;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  align-items: center;
}
.EditName {
  display: flex;
}
.EditName input {
  background-color: #303341;
  outline: none;
  border: none;
  padding: 0.5em;
  color: #db0a41;
  border-radius: 4px;
  width: 100%;
  font-size: 1em;
}

.EditName input::selection {
  background-color: #4b5063;
}

.Context {
  width: 170px;
  background-color: rgb(32, 39, 49);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 4px 0px 1px, rgba(0, 0, 0, 0.15) 2px 4px;
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.ContextItem {
  cursor: pointer;
  border-bottom: 1px solid #303341;
  padding: 0.4em;
}
.ContextItem:hover {
  background-color: #db0a41;
}

.ProjectList .Header {
  padding: 0em;
  border: none;
}
.Bolt {
  margin-right: 0.5em;
  cursor: pointer;
}
.Bolt.Active {
  color: #db0a41;
}
.Save {
  background-color: #1f212b;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  height: 20px;
  padding: 0.4em;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.Save:hover {
  color: #fff;
  background-color: #db0a41;
}

.Item {
  padding: 0.4em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.Type {
  margin-right: 5px;
  color: #4b5063;
  height: 10px;
  width: 10px;
}

.Expanded {
  margin-left: auto;
}
.Expanded svg {
  height: 1.4em;
}
.FolderTitle {
  display: flex;
}
.ScenesList {
  margin-left: 0.8em;
}

.Aspect {
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2em;
}

.Aspect.Active .SceneName {
  background-color: #db0a41;
}
