.Sidebar {
  min-width: 250px;
  height: 100vh;
  background-color: #181d25;
  margin-right:2px;
}

.Item {
  padding: 0.4em;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Item.Active {
  background-color: #db0a41;
}

.Header {
  display: flex;
  background-color: #1f212b;
}
.Project {
  width: 50%;
  padding: 0.5em;
  border-bottom: 1px solid #2a2c39;
  cursor: pointer;
  border-right: 1px solid #2a2c39;
}
.Scene {
  width: 50%;
  padding: 0.5em;
  border-bottom: 1px solid #2a2c39;
  cursor: pointer;
  border-right: 1px solid #2a2c39;
}

.Scene:hover,
.Project:hover {
  color: #db0a41;
}

.Scene.Active,
.Project.Active {
  border-bottom: 1px solid #db0a41;
}

.SceneTree {
  padding: 0.5em;
  overflow-y: scroll;
  height: 90vh;
}

.Item svg {
  height: 1em;
}

.ObjectName {
  width: 100%;
}
.Visible,
.Hidden {
  display: flex;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.Visible svg {
  color: #fff;
}

.Hidden svg {
  color: black;
}

.Expanded {
  margin-left: auto;
}
.Expanded svg {
  height: 1.4em;
}

.DropOver {
  border-bottom: 1px solid #db0a41;
}
.InGroup {
  padding-left: 1em;
}

.Context {
  width: 150px;
  background-color: rgb(32, 39, 49);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 4px 0px 1px, rgba(0, 0, 0, 0.15) 2px 4px;
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.ContextItem {
  cursor: pointer;
  border-bottom: 1px solid #303341;
  padding: 0.4em;
}
.ContextItem:hover {
  background-color: #db0a41;
}

.Title {
  border-bottom: 1px solid #949494;
}
.EditName input {
  background-color: #303341;
  outline: none;
  border: none;
  padding: 0.5em;
  color: #db0a41;
  border-radius: 4px;
  width: 80%;
  font-size: 1em;
  /* font-weight: 100; */
}
.EditName input::selection {
  background-color: #4b5063;
}
.Type {
  margin-right: 5px;
  color: #4b5063;
  display: flex;
  align-items: center;
}
.Type * {
  margin-right: 3px;
}
.Save {
  background-color: #1f212b;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  height: 20px;
}
.Item.Save:hover {
  color: #fff;
  background-color: #db0a41;
}
