.Objects {
  background-color: #202731;
  height: 100vh;
  padding: 0.7em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Plane {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background-color: #4b5063;
  cursor: pointer;
}

.Text {
  padding: 0.2em;
  font-size: 2em;
  cursor: pointer;
  color: #4b5063;
}
.Settings {
  margin-top: auto;
  margin-bottom: 1em;
}

.Settings svg {
  height: 2em;
  color: #4b5163;
  cursor: pointer;
}

.Settings svg:hover {
  color: #db0a41;
}

.Cube {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  color: #4b5063;
  cursor: pointer;
  margin-bottom: 1em;
}

.Circle {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  color: #4b5063;
  cursor: pointer;
  margin-bottom: 1em;
}
.Movie {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  color: #4b5063;
  cursor: pointer;
  margin-bottom: 1em;
}

.Item:hover {
  color: #db0a41;
}
.Plane.Item:hover {
  background-color: #db0a41;
}
