.Sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2a2c39;
}

.Item {
  padding: 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.Item.Active {
  background-color: #db0a41;
}
.Header {
  display: flex;
  background-color: #1f212b;
  justify-content: center;
  padding: 0.5em;
}
