.Active {
  background-color: #db0a41;
}

.Item {
  padding: 0.4em;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
}

.Button {
  width: 90%;
  height: 20px;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  background-color: #1f212b;
}
