.Timeline {
  width: 100%;
  background-color: #191f27;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.Timeline input {
  height: 20px;
  color: #8a8c8e;
  outline: none;
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
}

.Track {
  border-bottom: 1px solid #252734;
  height: 28px;
  display: flex;
  min-width: 200px;
  align-items: center;
  padding-left: 0.5em;
  color: #696c71;
}

.TrackName {
  cursor: pointer;
}

.Timelines {
  overflow-x: scroll;
  position: relative;
  overflow-y: hidden;
  padding-bottom: 2em;
  height: 291px;
  padding-left: 14px;
}

.Animation {
  display: flex;
  border-bottom: 1px solid #252734;
  height: 26px;
  padding-bottom: 1px;
  padding-top: 1px;
  opacity: 0.4;
  position: relative;
}

.AnimationHeader {
  position: sticky;
  width: fit-content;
  top: 0px;
  background-color: #191f27;
  z-index: 100;
  display: flex;
  border-bottom: 1px solid #252734;
  height: 26px;
  padding-bottom: 1px;
  padding-top: 1px;
}

.KeyFrameContainer {
  margin: 1px;
}
.KeyFrameContainer.Selected {
  z-index: 1;
}
.KeyFrame {
  height: 25px;
  min-width: 11px;
  border-radius: 2px;
  background-color: #222a3a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.KeyFrame.Active {
  background-color: #8d1237;
}
.KeyFrame.Selected {
  background-color: #db0a41;
  opacity: 1;
}
.KeyFrame.DropOver {
  background-color: #ffa1a1;
}

.VideoControls {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  color: #ecf0f4;
  /* margin-top: 50px; */
  height: 44px;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 20%;
  padding-right: 20%;
  margin-top: -44px;
  z-index: 2000;
}

.VideoControls .Repeat.Off {
  color: #545658;
}
.VideoControls .Repeat {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.VideoControls .Reverse {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.VideoControls .Play {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}
.VideoControls .Forward {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.VideoControls > div > svg:hover {
  color: #db0b41;
}

.Animations {
  display: flex;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track:horizontal {
  /* -webkit-box-shadow: inset 0 0 6px #16181f; */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /* background-color: #16181f;  */
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #8d1237;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #8d1237;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.Active {
  opacity: 1;
  z-index: 0;
}

.Track.Active {
  color: #dfe9e8;
}

.Tabs {
  display: flex;
  background-color: #0e1219;
  padding-top: 2px;
}
.Tab {
  background-color: #0e1219;
  cursor: pointer;
  padding: 0.5em;
  border-right: 1px solid #1d212b;
  border-top: 1px solid #1d212b;
  min-width: 40px;
  text-align: center;
}
.Tab.Active {
  background-color: #191f27;
  border-bottom: 1px solid #db0a41;
}

.Property {
  margin-left: 2em;
}

.Context {
  width: 150px;
  background-color: rgb(32, 39, 49);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 4px 0px 1px, rgba(0, 0, 0, 0.15) 2px 4px;
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.ContextItem {
  cursor: pointer;
  border-bottom: 1px solid #303341;
  padding: 0.4em;
}
.ContextItem:hover {
  background-color: #db0a41;
}

.HoverValue {
  width: 150px;
  background-color: rgb(55, 58, 74);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 4px 0px 1px, rgba(0, 0, 0, 0.15) 2px 4px;
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.HoverItem {
  border-bottom: 1px solid #303341;
  padding: 0.4em;
}

.Expander {
  height: 20px;
  width: 13px;
  margin-right: 0.5em;
}

.Time {
  height: 25px;
  min-width: 11px;
  max-width: 11px;
  border-radius: 2px;
  font-size: 0.6em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #5e648c;
  opacity: 0;
}
.Time.Second {
  color: #fff;
  opacity: 1;
}
.DurationMarker {
  border-left: 2px solid #db0a41;
  /* background-color: #db0a41; */
}

.TrackContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 269px;
}
