body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  color: #dfe9e8;
  font-size: 0.8em;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

canvas {
  background: #16181f;
  background-color: #191f2b;
  z-index: 1;
  /* border-radius: 4px; */
  /* box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12); */
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-radius: 0;
  border: 0px solid rgba(0, 0, 0, 0);
  -webkit-text-fill-color: #cacaca;
  -webkit-box-shadow: 0 0 0px 1000px #1f222b inset;
  transition: background-color 5000s ease-in-out 0s;
}
