.Color {
  width: auto;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5em;
  background-color: #181d25;
}
.lists {
  display: flex;
  flex-direction: column;
}
.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
}
.Image {
  background-color: #252734;
  width: 100%;
  height: 140px;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.Audio {
  background-color: #252734;
  width: 100%;
  height: 140px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Title {
  width: 30%;
}

.Video {
  display: flex;
  flex-direction: column;
}

.Video video {
  width: 90%;
}

.Play svg {
  height: 15px;
  padding: 0.5em;
  padding-top: 1em;
}

.Selected {
  background-color: #db0a41;
}
