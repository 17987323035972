.Active {
  background-color: #db0a41;
}

.Item {
  padding: 0.4em;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
  max-height: 200px;
  overflow: scroll;
}
