.chonky-chonkyRoot {
    background-color: #1f252f!important;
    color: white!important;
}

.MuiInputBase-root {
    color: white!important;
    border: 1px solid #232531!important;
    padding-left: 0.3em!important;
    font-size: 0.9em!important;
    background-color: #12161d!important;
}

.MuiTypography-colorTextSecondary,.MuiButton-root,.MuiButton-root:hover {
    color:  white!important;
}

.MuiButton-root.Mui-disabled {
    color: #707070!important;
}
div[class^="previewFile"] {
    background-color: #2a2c39!important;
    box-shadow: none!important;
}
div[class^="previewFile"]:hover {
    background-color: #db0a41!important;
}
.MuiButton-label  {
    color: white!important;

}

div[class^="fileIcon"] {
opacity: 0!important;
}
div[class^="gridFileEntryName"] {
font-size: 10px;
}
div[class^="folderBackSideTop"]::after {
    border-color: transparent transparent transparent transparent;
}
div.MuiListSubheader-root.chonky-browserMenuTooltip.MuiListSubheader-sticky.MuiListSubheader-gutters {
    display:none!important;
}
