.Scrubber {
  position: absolute;
  left: 0px;
  z-index: 1;
}

.Scrubber .Head {
  width: 15px;
  height: 15px;
  color: #00c283;
  cursor: pointer;
  position: relative;
}

.Scrubber .Guide {
  height: 1500px;
  width: 1px;
  background-color: #00c283;
  position: absolute;
  left: 7px;
  z-index: 100;
  pointer-events: none;
}
