.GradientPicker {
  background-color: rgb(55, 58, 74);
  padding: 10px 10px 0px;
  border-radius: 4px;
}

.popover {
  background-color: rgb(55, 58, 74) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px !important;
  width: 270px;
}

.controls-wrapper .ai,
.controls-wrapper .ai input {
  cursor: default;
  background-color: #303241;
  color: #fff;
}

.controls-wrapper .ai span {
  color: #fff;
}

.gpw .trigger {
  background-color: #303241;
}

.gpw .trigger .inner {
  width: 90px;
  height: 30px;
}

.gpw .trigger .inner {
  transform: rotate(180deg);
}

.Button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #353a47;
  padding: 8px;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 100;
  /* width: 82%; */
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
}
.Button:hover {
  background-color: #db0741;
}
