.Property {
  display: flex;
  width: 25%;
  align-items: center;
}

.Title {
  width: 30%;
}

.X {
  color: #e03838;
  padding-right: 0.5em;
}
.Y {
  color: #38e086;
  padding-right: 0.5em;
}
.Z {
  color: #3890e0;
  padding-right: 0.5em;
}
.Property input {
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
}
.lists {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.Group {
  padding: 0.5em;
}

input.Checkbox {
  cursor: pointer;
}

.GroupTitle {
  border-bottom: 1px solid #0e1219;
  padding: 3px;
  margin-bottom: 8px;
  text-align: center;
  background-color: #282f3c;
}
