.Components {
  display: flex;
}
.Component {
  height: 80px;
  width: 80px;
  background-color: #0e1219;
  border-radius: 4px;
  margin: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  /* box-shadow: 0px 3px 2px -20px rgba(0, 0, 0, 0.2),
    0px 5px 0px 0px rgba(0, 0, 0, 0.14), 0px 4px 9px 8px rgba(0, 0, 0, 0.12); */
  cursor: pointer;
}

.Component:hover {
  background-color: #db0741;
}
