.TestBars {
  display: flex;
  width: 80px;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
}

.Bar1 {
  width: 25%;
  height: 100%;
  background-color: #00c0c0;
}
.Bar2 {
  width: 25%;
  height: 100%;
  background-color: #02c001;
}
.Bar3 {
  width: 25%;
  height: 100%;
  background-color: #c001c0;
}
.Bar4 {
  width: 25%;
  height: 100%;
  background-color: #c00001;
}
