.Properties {
  padding: 0.5em;
  width: 440px;
  height: 100vh;
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  margin-right: 2px
}

.Curves {
  display: -webkit-flex;
  display: flex;

  flex-wrap: wrap;
}
.Curve {
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #0e1219;
  box-shadow: none;

  box-sizing: border-box;
  cursor: pointer;
}

.Curve.Selected {
  border: 1px solid #ca0c3e;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.ItemName {
  display: flex;
  justify-content: center;
  height: 20px;
  border-bottom: 1px solid #db0b41;
  margin-bottom: 0.2em;
}

.None {
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 100;
  height: 100%;
}

.CurveContainer {
  position: relative;
  width: 40%;
  margin: 1em;
}

.CurveOverlay {
  width: 100%;
  height: 100%;
  top: 0px;
  background: #0e1219;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}

.CurveOverlay:hover {
  opacity: 0.6;
}

.ExampleContainer {
  display: flex;
  height: 50px;
  align-items: center;
  width: 100%;
}

.Example {
  background-color: #db0a41;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: 1s;
  margin-left: 5%;
}

.Curvy {
  display: flex;
  flex-wrap: wrap;
}
.Editor {
  background-color: #0e1219;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.CheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.Checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #12161d;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.CheckboxContainer:hover input ~ .Checkmark {
  border: 1px solid white;
}

/* When the checkbox is checked, add a blue background */
.CheckboxContainer input:checked ~ .Checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.CheckboxContainer input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.CheckboxContainer .Checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
