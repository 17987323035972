.ViewBar {
  background-color: #303341;
  height: 100vh;
  padding: 0.7em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  box-sizing: border-box;
}
.Edit svg {
  height: 3em;
  color: #4b5163;
  cursor: pointer;
}

.Edit svg:hover {
  color: #db0a41;
}
