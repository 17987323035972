.ProjectViewer {
  display: flex;
  flex-direction: column;
}

.Projects {
  display: flex;
  flex-wrap: wrap;
}

.Project {
  background-color: #2a2c39;
  border-radius: 4px;
  margin: 1em;
  height: 90px;
  width: 160px;
  display: flex;
  justify-content: center;
  padding: 0.3em;
  cursor: pointer;
  position: relative;
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
}

.Engine_Version {
  font-size: 9px;
}

img {
  object-fit: contain;
}

.Overlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(219, 10, 65, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  border-radius: 4px;
}
.Overlay:hover {
  opacity: 1;
}

.Project.New {
  background-color: #181f2b;
}
.Project.New > div {
  font-size: 3em;
  font-weight: 100;
  color: #7a8ca9;
}

.ProjectViewer h2 {
  margin: 1em;
  font-weight: 100;
}
