.Properties {
  padding: 0.5em;
  width: 440px;
  height: 100vh;
  background-color: #181d25;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-right: 2px;
}

.Properties input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  height: 20px;
  color: #8a8c8e;
  outline: none;
  font-size: 15px;
  width: 61%;
  cursor: ew-resize;
}

.Properties label {
  cursor: pointer;
}

.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Title {
  font-size: 1.1em;
  background-color: #282f3c;
  padding: 0.3em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #161a21;
}

.lists {
  display: flex;
}

.Group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  background-color: #1f252f;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
}

.GroupTitle {
  cursor: pointer;
}

.Open svg {
  width: 10px;
  float: left;
  margin-left: 10px;
  margin-top: 6px;
}

input.Slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  opacity: 1;
  background-color: rgb(24, 29, 37);
  border: none;
  height: 6px;
  border-radius: 4px;
  /* color: #232531; */
  outline: none;
  font-size: 15px;
  width: auto;
  cursor: ew-resize;
  margin: 0.5em;
}
input.Slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: #db0741; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 505%;
}
input:checked {
  background-color: #db0741;
}

.ItemName {
  display: flex;
  justify-content: center;
  height: 20px;
  border-bottom: 1px solid #db0b41;
  margin-bottom: 0.2em;
}
.Button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #1f252f;
  padding: 8px;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -20px rgba(0, 0, 0, 0.2),
    0px 5px 20px 0px rgba(0, 0, 0, 0.14), 0px 4px 20px 8px rgba(0, 0, 0, 0.12);
  font-size: 2em;
  font-weight: 100;
  position: sticky;
  bottom: 64px;
  /* width: 82%; */
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
}
.Button:hover {
  background-color: #db0741;
}
