textarea {
  background-color: #16181f;
  border: none;
  height: 60px;
  color: #8a8c8e;
  outline: none;
  font-size: 1em;
  width: 100%;
  border-radius: 4px;
  padding: 0.4em;
  box-sizing: border-box;
}
textarea:focus {
  border-bottom: 1px solid #db0a41;
}

.Color {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.lists {
  display: flex;
  flex-direction: column;
}
.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
}
.Image {
  background-color: #252734;
  width: 100%;
  height: 140px;
  border-radius: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.Title {
  width: 30%;
}

.Group {
  padding: 0.5em;
  max-height: 200px;
  overflow-y: scroll;
}

.MaskItem {
  padding: 0.4em;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.Button {
  width: 40%;
  height: 20px;
  padding: 0.2em;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  background-color: #303341;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Active {
  background-color: #db0a41;
}
