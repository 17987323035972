.Properties {
  padding: 0.5em;
  width: 440px;
  height: 100vh;
  background-color: #181d25;
  display: flex;
  flex-direction: column;
}

.Properties input {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  height: 20px;
  color: #8a8c8e;
  outline: none;
  font-size: 15px;
  width: 61%;

  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
}

.Curves {
  display: -webkit-flex;
  display: flex;

  flex-wrap: wrap;
}
.Curve {
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #0e1219;
  box-shadow: none;

  box-sizing: border-box;
  cursor: pointer;
}

.Curve.Selected {
  border: 1px solid #ca0c3e;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.ItemName {
  display: flex;
  justify-content: center;
  height: 20px;
  border-bottom: 1px solid #db0b41;
  margin-bottom: 0.2em;
}

.None {
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 100;
  height: 100%;
}

.CurveContainer {
  position: relative;
  width: 40%;
  margin: 1em;
}

.CurveOverlay {
  width: 100%;
  height: 100%;
  top: 0px;
  background: #0e1219;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}

.CurveOverlay:hover {
  opacity: 0.6;
}

.ExampleContainer {
  display: flex;
  height: 50px;
  align-items: center;
  width: 100%;
}

.Example {
  background-color: #db0a41;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: 1s;
  margin-left: 5%;
}

.Curvy {
  display: flex;
  flex-wrap: wrap;
}
.Editor {
  background-color: #0e1219;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Selected {
  background-color: #db0a41;
}
