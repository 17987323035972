.Canvas {
  width: 100%;
  background-color: #1f212b;
  background-color: #0e1219;
}
.Canvas.Engine {
  background-color: rgba(0, 0, 0, 0);
}

.Player {
  width: 960px;
  height: 540px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.Button {
  width: 40px;
  height: 20px;
  padding: 0.2em;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  background-color: #2a2c39;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EngineButton {
  height: 20px;
  padding: 0.2em;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  background-color: #2a2c39;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EngineButton.Active {
  background-color: #db0741;
}
.Button.Active {
  background-color: #db0741;
}

.Toolbar {
  display: flex;
}

.Expand {
  margin: 1em;
  margin-left: auto;
  cursor: pointer;
}
.Expand svg {
  height: 1.5em;
}

.Engine,
.Engine canvas {
  background-color: rgba(0, 0, 0, 0);
}

.DataView {
  height: 200px;
}
