.TopBar {
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
}
.TopBar > img {
  margin-left: auto;
  margin-right: auto;
  height: 50%;
}

.Left {
  width: 40%;
  padding-left: 1em;
  display: flex;
  height: 100%;
}
.Right {
  width: 40%;
  padding-right: 1em;
  display: flex;
  align-items: center;
}

.Left > div {
  margin-right: 1em;
}

.Profile {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.SignOut {
  display: flex;
  border-radius: 4px;
  border: 1px solid red;
  padding: 8px;
  margin-left: 10px;
  cursor: pointer;
}

.SignOut:hover {
  background-color: red;
}
.szh-menu-button {
  background-color: red;
}
