.Main {
  display: flex;
  flex-direction: column;
}

.ProjectViewer {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background-color: #181d25;
}

.Projects {
  display: flex;
  flex-wrap: wrap;
}

.Project {
  background-color: #2a2c39;
  border-radius: 4px;
  margin: 1em;
  height: 90px;
  width: 160px;
  display: flex;
  justify-content: center;
  padding: 0.3em;
  cursor: pointer;
  position: relative;
  align-items: center;
  color: black;
}

img {
  object-fit: contain;
}

.Overlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(219, 10, 65, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  border-radius: 4px;
}
.Overlay:hover {
  opacity: 1;
}

.Project.New {
  background-color: #181f2b;
}
.Project.New > div {
  font-size: 3em;
  font-weight: 100;
  color: #7a8ca9;
}

.ProjectViewer h2 {
  margin: 1em;
  font-weight: 100;
}

.Header {
  padding: 0.5em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #343848;
  font-weight: 100;
}
.Header svg {
  height: 1em;
  margin-left: auto;
}
.Header .Item {
  width: 20%;
  cursor: pointer;
}
.Header .Item:hover {
  color: #db0a41;
}
.ProjectList {
  padding: 0.5em;
}
.Project {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
}
.Scene {
  padding-left: 1em;
  cursor: pointer;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
.Scene.Active {
  background-color: #db0a41;
}
.NewScene {
  margin-left: auto;
  cursor: pointer;
}
.EditName {
  display: flex;
}
.EditName input {
  background-color: #303341;
  outline: none;
  border: none;
  padding: 0.5em;
  color: #db0a41;
  border-radius: 4px;
  width: 100%;
  font-size: 1em;
}

.EditName input::selection {
  background-color: #4b5063;
}

.Context {
  width: 150px;
  background-color: rgb(32, 39, 49);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 4px 0px 1px, rgba(0, 0, 0, 0.15) 2px 4px;
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.ContextItem {
  cursor: pointer;
  border-bottom: 1px solid #303341;
  padding: 0.4em;
}
.ContextItem:hover {
  background-color: #db0a41;
}

.ProjectList .Header {
  padding: 0em;
  border: none;
}
.Bolt {
  margin-right: 0.5em;
  cursor: pointer;
}
.Bolt.Active {
  color: #db0a41;
}
.Save {
  background-color: #1f212b;
  border-radius: 4px;
  margin-left: auto;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  height: 20px;
}
.Item.Save:hover {
  color: #fff;
  background-color: #db0a41;
}
.Item {
  padding: 0.4em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.Type {
  margin-right: 5px;
  color: #4b5063;
  height: 10px;
  width: 10px;
}

.Expanded {
  margin-left: auto;
}
.Expanded svg {
  height: 1.4em;
}
.FolderTitle {
  display: flex;
}
.ScenesList {
  margin-left: 0.8em;
}

.Button {
  width: 83px;
  height: 31px;
  padding: 0.2em;
  border-radius: 4px;
  text-align: center;
  margin: 2.5em;
  cursor: pointer;
  background-color: #2a2c39;
  display: flex;
  justify-content: center;
  align-items: center;
}
