.App {
}

.Centre {
  width: 60%;
  background-color: #191f27;
}

.Hidden {
  display: none;
}

.MainApp {
  display: flex;
}

.MainApp.Hidden {
  display: none;
}
.Loading {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 20%;
}
.Bar {
  width: 500px;
  border-radius: 4px;
  height: 8px;
  background-color: #404358;
  overflow: hidden;
}
.Progress {
  background-color: #db0a41;
  height: 100%;
}

.PropertyMenu {
  display: flex;
}

.PropertyMenu > div {
  padding: 0.5em;
  background-color: #202731;
  border-radius: 4px;
  margin: 0.5em;
  cursor: pointer;
}

.CodeEditor .CodeMirror {
  height: 600px;
}
