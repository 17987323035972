.ImageViewer {
  padding: 1em;
}

.Images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Image {
  background-color: #2a2c39;
  border-radius: 4px;
  margin: 1em;
  height: 90px;
  width: 155px;
  display: flex;
  justify-content: center;
  padding: 0.3em;
  cursor: pointer;
  position: relative;
}

img {
  object-fit: contain;
}

.Overlay {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(219, 10, 65, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  border-radius: 4px;
}
.Overlay:hover {
  opacity: 1;
}
.AddContainer {
  width: 40px;
  height: 20px;
  padding: 0.2em;
  border-radius: 4px;
  text-align: center;
  margin: 0.5em;
  cursor: pointer;
  background-color: #db0a41;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}
