.Color {
  width: auto;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5em;
  background-color: #181d25;
}
.lists {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.Property {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
}

.OffsetProperty {
  display: flex;
  width: 25%;
  align-items: center;
}

.Title {
  width: 30%;
}

.Property input {
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
}

.OffsetProperty input {
  border: 1px solid #232531;
  padding-left: 0.3em;
  font-size: 0.9em;
  background-color: #12161d;
  border-radius: 3px;
}

input.Checkbox {
  cursor: pointer;
}
.Group {
  padding: 0.5em;
}

.X {
  color: #e03838;
  padding-right: 0.5em;
}
.Y {
  color: #38e086;
}
