.ColourPicker {
  display: grid;
  margin-top: 64px;
  font-size: 14px;
  float: left;
  width: 100%;
  position: absolute;
}

.ColourPicker .ColourBox {
  width: 20px;
  height: 20px;
  background-color: #000;
  cursor: pointer;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}

.Text label {
  color: #fff;
  text-align: left;
}

.ColourPicker input {
  background-color: #303241;
  border: none;
  border-radius: 2px;
  height: 20px;
  color: #8a8a8a;
  padding-left: 5px;
  outline: none;
}

.ColourPicker .Dropdown {
  width: 300px;
  font-size: 14px;
  color: #747679;
  text-align: left;
  cursor: pointer;
  float: left;
  position: relative;
  
  background-color: #333536;
  border-radius: 4px;
  display: none;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
}
