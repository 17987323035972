.cm-s-cobalt.CodeMirror {
  background: #0e1219;
}
.cm-s-cobalt .CodeMirror-gutters {
  background: #181e29;
}

.ReactCodeMirror {
  border-top: 1px solid #282f3c;
}

#logger {
  height: 200px;
  width: 100%;
}
